import React from 'react';
import {  NavLink } from 'react-router-i18n';

export default function Breadcrumb({ url, sectionClass, inicio, namePage }) {
  return (
    <section className={`${sectionClass}`}>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb -bg-section">
                <li className="f_p f--size-15  breadcrumb-item">
                    <NavLink className="t_color" to={`${url}`}>{inicio}</NavLink>
                </li>
                <li className="f_p f--size-15 p_color breadcrumb-item active">
                    {namePage}
                </li>
            </ol>
        </nav>
    </section>
  )
}
