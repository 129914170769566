import React from 'react';
import { useTranslation } from 'react-i18next';
import BannerLanding from '../components/StaffAugmentation/banner/BannerLanding';
import VideoStaff from '../components/StaffAugmentation/videoStaff/VideoStaff';
import TechTalent from '../components/StaffAugmentation/TechTalent/TechTalent';
import FormLand from '../components/StaffAugmentation/form/FormLand';
import SliderExpert from '../components/StaffAugmentation/SliderExpert/SliderExpert';
import './../components/StaffAugmentation/staff-augmentation.css'
import MetaDecorator from '../components/MetaDatos/MetaDecorator';
import Brochures from '../components/Services/Brochures/Brochures';
import ReactGA from 'react-ga';
import { ID_GOOGLE_ANALYTICS } from '../assets/Constants';

    // Configuración de Google Analytics
    ReactGA.initialize ({ID_GOOGLE_ANALYTICS}); 
    ReactGA.pageview ("/staff-augmentation");

function StaffAugmentation() {

  const { t } = useTranslation();

  return (
    <>
      <MetaDecorator 
        title={t('meta_datos.meta_title_staff')}
        description={t('meta_datos.meta_description_staff')}
        url="staff-augmentation" />
      <section className='staff-augmentation bg-darkblue '>
        <div className="bg-wrap-border staff-augmentation bg_folder sec_pad" id="landing">
            <BannerLanding />
            <div className='container'>
              <SliderExpert />
              <div className='text-center pt-5'>
                  <a href="#form" class="mb-1 btn_six f_p f_600 border-radius-25 btn-folder f--size-30">{t('staff.folder_staffAug.btn_talk')}</a>
              </div>
            </div>
            
        </div>
        <section className='bg-wrap-border bg_video sec_pad' id='video'>
          <VideoStaff />
        </section>
        <section className='bg-wrap-border bg_talent_fondo  sec_pad' id='good'>      
          <TechTalent />
        </section>
        <div className='bg-wrap-border bg_form sec--area' id='form'>
          <Brochures />
          <FormLand />
        </div>

      </section>
    </>
  );
}

export default StaffAugmentation;

