import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import "./bannerH.css"
import "./video.css"
import Atropos from 'atropos/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";
import {  NavLink } from 'react-router-i18n';
import YouTube from "react-youtube";

function Video({ isPlaying, setIsPlaying }) {

    const playVideo = () => {
      setIsPlaying(!isPlaying);
    };


    
    return(
            <>                
                <div className={`video_info ${!isPlaying ? ' ' : 'video_info_no_img' }`} >
                {isPlaying && (
                    <YouTube
                        videoId="CKhhxRhBwcs"
                        opts={{
                            width: "888",
                            height: "500",
                            playerVars: {
                            autoplay: isPlaying ? 1 : 0,
                            autohide: 1,
                            controls: 0,
                            modestbranding: 1,
                            rel: 0,
                            showinfo: 0,
                            },
                        }}
                        onEnd={playVideo}
                    />
                )}               
                    {!isPlaying && (
                        <div className='video_icon' onClick={playVideo}><i className="ti-control-play"  ></i></div>
                    )}
                </div>               
            </>
        );
}

function AreaSlider ({children, className}) {
    return(
        <>
            <div className={`area-slider d-flex flex-column ${className}`}>
                {children}
            </div>
        </>
    );
}


function BannerHome (){

    const { t } = useTranslation('translation', 
    { keyPrefix: 'homePage.bannerHome' });

    const [isPlaying, setIsPlaying] = useState(false);




    return(
        <section className='banner-home vh-50'>
            <Swiper 
                autoplay={{ delay: 60000000, disableOnInteraction: true }}
                modules={[Autoplay, Pagination]}
                pagination={{
                    clickable: true,
                }}
                onAutoplayPause={false}
                className="slider--home line-break">
                <SwiperSlide >                 
                        <Atropos 
                        shadow={false}
                        shadowScale={0}
                        shadowOffset={50}
                        highlight={false} 
                        className='h-100'>
                            <AreaSlider className='slider-1 justify-content-center align-items-center'>
                                <h1 data-atropos-offset="0" className='w_color f_600 text-center'>
                                    <Trans i18nKey="homePage.bannerHome.titleBannerHome">
                                        Staffing<span> en desarrollo de software y talento humano</span>
                                    </Trans>
                                </h1>
                                <div>
                                <NavLink className='position-relative z-index-9 w_color mt-5 d-flex align-items-center' activeclass="active" spy="true" offset={0} duration={500} to={`/staff-augmentation`}>
                                    <h3 className='f--size-30 f_400 pr-2'>{t('btn_slider_one')}</h3> 
                                    <i className="f_600 ti-angle-right"></i>
                                </NavLink> </div>
                                <img src={require ('img/home/figura-1.svg').default} data-atropos-offset="-5" className='figura-1' alt="Figura del slider 1 en 3D" />
                                <img src={require ('img/home/figura-2.svg').default} data-atropos-offset="5" className='figura-2' alt="Figura del slider 1 en 3D" />
                                <img src={require ('img/home/circulos.svg').default} data-atropos-offset="-5"  alt="Figura del slider 1 en 3D" />
                                <img src={require ('img/home/circulos-2.svg').default} data-atropos-offset="5"  alt="Figura del slider 1 en 3D" />
                            </AreaSlider>
                        </Atropos>
                    
                </SwiperSlide>

                <SwiperSlide className="swiper-slide" data-swiper-autoplay={isPlaying ? 40000 : 6000}>
                    <div>                        
                        <Atropos 
                        shadow={false}
                        shadowScale={0}
                        shadowOffset={50}
                        highlight={false} >
                            <AreaSlider className="slider-3 justify-content-center align-items-center">
                                <div className="container">
                                    <Video isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
                                </div>
                            </AreaSlider>
                        </Atropos>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div>                        
                        <Atropos 
                        shadow={false}
                        shadowScale={0}
                        shadowOffset={50}
                        highlight={false} >
                            <AreaSlider className="slider-2  container ">
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <h2 data-atropos-offset="0" className='f--size-30 w_color f_600 text-left'>
                                            <Trans i18nKey="homePage.bannerHome.subtitleBannerHome">
                                                Facilitando los procesos de<span> adhesión tecnologica</span> de las compañías, <span>evolucionando</span> con ellas al ritmo de los mercados.
                                            </Trans>
                                        </h2>
                                        <NavLink className='position-relative z-index-9 w_color mt-5 d-flex align-items-center' activeclass="active" spy="true" offset={0} duration={500} to={`/services`}>
                                            <h3 className='f--size-30 f_400 pr-2'>{t('btn_slider_home')}</h3> 
                                            <i className="f_600 ti-angle-right"></i>
                                        </NavLink> 
                                    </div>
                                    <img src={require ('img/home/slider2-figura-1.svg').default} data-atropos-offset="5" alt="Figura del slider 3D" loading="lazy" />
                                    <img src={require ('img/home/slider2-figura-3.svg').default} data-atropos-offset="-5" alt="Figura del slider 3D" loading="lazy" />
                                    <img src={require ('img/home/slider2-figura-2.svg').default} data-atropos-offset="0" alt="Figura del slider 3D" loading="lazy" />
                                </div>
                            </AreaSlider>  

                        </Atropos>

                    </div>
                </SwiperSlide>

            </Swiper>
        </section>
    )
}    

export default BannerHome;


