import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import "./sliderExpert.css";
import { Navigation, Pagination } from "swiper";
// Import Swiper styles
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.css';



function Advantage ({ text }) {
  
  return (
    <div className="col-lg-12 col-md-12 col-sm-10">
      <div className='py-3 mb-3 folder-child'>         
        <p className='f_500 w_color text-left text-uppercase'>{text}</p>        
      </div>
    </div>  
  )
}

export default function SliderProfile() {

  const { t } = useTranslation('translation', 
  { keyPrefix: 'staff' });

  return (

      <section className='profile--slider bg-section line-break'>
        <h2 className='w-50 pl-3 f- text-uppercase text-left w_color'>{t('folder_staffAug.we_are')}</h2>
        
        <Swiper
          loop={true}
          spaceBetween={25}
          pagination={{
          clickable: true,
          }}
          navigation={false}
          breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
          modules={[Pagination, Navigation]}
          className="StaffSwiper w_color"
      >
        <SwiperSlide>
          <div className='row'>
            <Advantage text={t('folder_staffAug.item_app')}/>
            <Advantage text={t('folder_staffAug.item_devops')}/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='row'>
            <Advantage text={t('folder_staffAug.item_stack')}/>
            <Advantage text={t('folder_staffAug.item_micro')}/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='row'>
          <Advantage text={t('folder_staffAug.item_software')}/>
            <Advantage text={t('folder_staffAug.item_frontend')}/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='row'>
            <Advantage text={t('folder_staffAug.item_api')}/>
            <Advantage text={t('folder_staffAug.item_agil')}/>
          </div>
        </SwiperSlide>
        </Swiper>

      </section>

  )
}
