import React from 'react';
import {  NavLink } from 'react-router-i18n';
import ParallaxTitle from '../../Titles/ParallaxTitle';
import { useTranslation, Trans } from 'react-i18next';
import "./staffHome.css"

function StaffAugmentation () {

    const { t } = useTranslation('translation', 
    { keyPrefix: 'homePage.staff_home' });
        
        return(
        
            <section className="our--process-area" id="process">
                <div className='container'>
                    <ParallaxTitle 
                        classDiv="text-left"
                        Pclass="w_color f--size-30 text-left "
                        h2Class="m_color f--size-40 mb-4 f_600"
                        Title={t('title')}
                        Paragraph={t('subtitle')}
                     />
               
                    <div className="row ">
                        <div className="col-lg-8 col-md-12 d-flex justify-content-center " >                                                    
                            <div className="position-relative">
                                <p className="text-left f--size-25 pb-4 w_color f_500">
                                    <Trans i18nKey="homePage.staff_home.text">
                                    Nos integramos al <strong>cumplimiento de la visión de negocio</strong> de las organizaciones<strong> facilitando su crecimiento e 
                                    impulsando los procesos</strong> de transformación tecnológica con <strong>talento TI especializado.</strong>
                                    </Trans>
                                </p>
                                <NavLink className='mb-1 btn_six f_p f_600 border-radius-25 btn-folder f--size-20' activeclass="active" spy="true" offset={0} duration={500} to={`/contact`}>
                                    {t('know_more')}
                                </NavLink>
                            </div>        
                        </div>
                    </div>
                </div>
            </section>
        
        );
    }


export default StaffAugmentation;

