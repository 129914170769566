import React from 'react';
import './postList.css';
import SearchList from "../SearchList/SearchList";


export default function PostList() {


  return (
    <section className='post-list -bg-section'>
        <div className="container">
            <SearchList />
        </div>
    </section>
  )
}