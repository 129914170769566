import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import "./profile-slider.css";
import { Navigation, Pagination } from "swiper";


function Advantage ({ img, text }) {
  
  return (
    <div className="col-lg-6 col-md-6">
      <div className='p-4'>
          <div className='pb-3 d-flex justify-content-center'>
              <img className="w-50" src={require(`img/careers/advantageIconos/${img}`)} alt={text} loading="lazy" />
          </div>
          <div className=' text-center'>
              <p className='p_color'>{text}</p>
          </div>
      </div>
    </div>  
  )
}

export default function SliderProfile() {

const { t } = useTranslation('translation', { keyPrefix: 'profile_job' });

  return (

      <section className='profile--slider sec_pad -bg-section'>
        <h2 className='t_color text-center mb-4 f_600'>{t('advantage_title')}</h2>
        <div className='container'>
          <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{ clickable: true, }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mt-3"
          >
            <SwiperSlide>
              <div className='row'>
                <Advantage img="Entorno-agil.png" text={t("profile_advantage.advantage_flexibility")} />
                <Advantage img="Impulsaras-tu-carrera.png" text={t("profile_advantage.advantage_future")} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='row'>
                <Advantage img="Trabajaras-tecnologias.png" text={t("profile_advantage.advantage_innovation")} />
                <Advantage img="Desarrollaras-potencial.png" text={t("profile_advantage.advantage_diversity")} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='row'>
                <Advantage img="Generaras-impacto.png" text={t("profile_advantage.advantage_impact")} />
                <Advantage img="Estaras-actualizado.png" text={t("profile_advantage.advantage_update")} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='row'>
                <Advantage img="Trabajaras-remoto.png" text={t("profile_advantage.advantage_comfort")} />
                <Advantage img="Aprenderas-DreamCoders.png" text={t("profile_advantage.advantage_mentoring")} />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

  )
}
