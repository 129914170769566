import React from 'react';
import './bottons.css';
import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";

export const BottonsNavSwiper = () => {
    return (
        <div className='btn-nav-swiper'>
            <div className="swiper-button-next">
                <IoIosArrowDropright size={35}/>
            </div>
            <div className="swiper-button-prev">
                <IoIosArrowDropleft size={35}/>
            </div>
        </div>
    );
}



