import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import './bannerCareers.css'

export default function BannerCareers() {

    const { t } = useTranslation();

  return (
            <section className="banner--careers" id="bannerCareers">
                <div className="container">              
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 w_color text-left ">
                      <h1 className="f--size-80 f_600 l_height60 mb_40 line-break">
                        <Trans i18nKey="careers.banner_primary_title">
                          Join and Develop <span className='f--size-40 f_600'>your purpose in DreamCode</span>
                        </Trans>
                      </h1>  
                      <h2 className="f--size-40 f_600">{t('careers.banner_primary_subtitle')}</h2>  
                      <p className="f--size-20 f_500">{t('careers.banner_primary_text')}</p>        
                    </div>
                  </div>  
                </div>
            </section>
  )
}
