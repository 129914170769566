import React from 'react';
import './experience.css'
import { useTranslation } from 'react-i18next';

function Experince() {

    const { t } = useTranslation('translation', { keyPrefix: 'aboutPage.what-do-we-think' });

  return (
    <section className="about-experience position-relative py_60" id="experience">
        <div className="container">
            <div className='row justify-content-center experience-content'>
                <div className="col-sm-12 col-md-10 col-xl-9">               
                    <h2 className="f--size-40 f_sm_40 m_color f_600 pb-4">{t("title")}</h2>                 
                </div>
                <div className='col-sm-12 col-md-10 col-xl-9'>
                    <div className='items-what-think'>
                        <ul>
                            <li>{t('item_projects')}</li>
                            <li>{t('item_must_focus')}</li>
                            <li>{t('item_revolutionize')}</li>
                        </ul>
                    </div>
                </div>                
            </div>      
        </div>
    </section>
  )
}

export default Experince;