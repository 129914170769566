import React from 'react';
import './banner.css'


class Banner extends React.Component{
    render(){
           const {children, classBanner } = this.props;
           
           return(
            <section className={`${classBanner} banner text-center`} id=''>
                <div className="container">
                    <div className="banner-content row align-items-center ">                 
                        {children}
                    </div>
                </div>
            </section>
           )
    }
}

export default Banner;
