import React, {useRef, useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Trans, useTranslation } from 'react-i18next';
import './formCareers.css'
import { RECAPTCHA_SITEKEY } from 'assets/Constants';
import emailjs from '@emailjs/browser';
import swal from 'sweetalert';
import ReactGA from 'react-ga';

function FormCareers ({ btn_color }) {

    const { t } = useTranslation();

    const form = useRef();


    // Validar ReCAPTCHA

    const [recaptchaValid, setRecaptchaValid] = useState(false);

    const RecaptchaChange = (value) => {

        setRecaptchaValid(!!value);
        console.log("Captcha value:", value);
    }
    
    // Envio del Formulario

    const sendEmail = (event) => {

        event.preventDefault();

        if(recaptchaValid) {
            emailjs.sendForm('service_gmail', 'template_careers', event.target, 'user_DK3x36R2nsn4W5NwGBIou')

            .then((result) => {
                swal({
                    title: t('forms.form_text_modal'),
                    icon: "success",
                });
                console.log(result.text + " ENVIADO");
            }, (error) => {
                console.log(error.text + " ERROR");
            });

            setTimeout(function() {
                event.target.reset(); // Resetea el formulario después de 3 segundos (3000 milisegundos)
            }, 2000);

        } else {
            /*swal({
                title: t('forms.form_text_error'),
                icon: "warning",
            });*/
            console.log(t('forms.form_text_error'));
        }
  
    };


    // Rastreo de Evento

    function handleClick() {
        ReactGA.event({
            category: 'Botón',
            action: 'Clic en botón de enviar formulario',
        });
    }

    return (

        <form ref={form} onSubmit={sendEmail} className="mb-4 needs-validation was-validated" method="post" id="careeersForm" >
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <input className="form-control input" type="text" name="name" id="name" placeholder=" " required/>
                        <label className="placeholder">{t("forms.form_input_name")}</label>    
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <input className="form-control input" type="email" name="email" id="inputEmail4" placeholder=" " required/>
                        <label className="placeholder">{t("forms.form_input_email")}</label>    
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <input className="form-control input" type="tel" pattern="[0-9]*" inputMode="numeric" placeholder=" " required />
                        <label className="placeholder">{t("forms.form_input_celular")}</label>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <input className="form-control input" type="text" name="linkedin" id="Linkedin" placeholder=" " required/>
                        <label className="placeholder">Linkedin</label>    
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group position-relative">
                        <label className="w_color" htmlFor="validationServer02">{t("forms.form_label_file_name")}<code className="w_color"> (Max. 400kb)</code></label>
                        <input type="file" name="file" id="file" accept="application/pdf, .doc, .docx, .odf" className="inputfile" required/>
                            <label className={`btn-file btn_form ${btn_color} text-left f_p w-100 f_600`} >
                                <span>{t("forms.form_input_file")}<i className="ti-file"></i></span>
                            </label>
                            <div className="valid-feedback">{t("forms.form_label_file_valid")}</div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label className="w_color" htmlFor="validationServer02">{t("forms.form_select_fluently")}</label>
                        <select name="ingles" placeholder={t("forms.form_select_fluently")} className={` w_color custom-select mr-sm-2 f_p f_600`}>
                            <option name="Si" value="Si">{t("forms.form_option_yes")}</option>
                            <option name="No" value="No">No</option>
                        </select>                                        
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <input className="form-control input" type="text" id="rol" name="rol" placeholder=" " required/>
                        <label className="placeholder">{t("forms.form_input_rol")}</label>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-check text-left mb-3">
                        <input className="form-check-input position-static" type="checkbox" id="blankCheckbox" value="option1" aria-label="..." required/>                        
                        <label className="pl-2 w_color form-check-label" htmlFor="gridCheck">
                            <Trans i18nKey="forms.form_label_policy">
                                I have read and accept the <a href={require ("assets/documents/Tratamiento_de_Datos_Dreamcode.pdf")} className='f_600 w_color' download="Tratamiento_de_Datos_Dreamcode.pdf">Privacy policy </a>
                            </Trans>
                        </label>                    
                    </div>
                </div>
            </div>
            <div className="form-group mb-3">
                <ReCAPTCHA
                    sitekey={`${RECAPTCHA_SITEKEY}`}
                    onChange={RecaptchaChange} />
            </div>
 
            <div className='position-relative' id="infoContacts">    
                <button onClick={handleClick} disabled={!recaptchaValid} type="submit" className={`${btn_color} f_p f_500`} >
                    <div className="svg-wrapper-1">
                        <div className="svg-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                        </svg>
                        </div>
                    </div>
                    <span>{t('careers.apply')}</span>
                </button>
            </div>
        </form>

        )
}

export default FormCareers;
