import React, { Suspense } from "react";
import { InView } from "react-intersection-observer";

const Placeholder = ({children}) => (
    <div style={{ height: "200px", background: "#f0f0f0" }}>
      <p>Cargando contenido...</p>
      {children}
    </div>
  );


const Inview = ({children, headers}) => {
    return (
        <>         
            <InView triggerOnce={true} rootMargin="200px">
            {({ inView, ref }) => (
            <div ref={ref}>
                {inView ? (
                <Suspense fallback={<Placeholder><h2>{headers}</h2></Placeholder>}>
                    {children}
                </Suspense>
                ) : (
                <Placeholder>
                    <h2>{headers}</h2>
                </Placeholder>
                )}
            </div>
            )}
            </InView>
        </>
    );
}

export default Inview;


