import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './bannerServ.css'
import Banner from '../../Banner/Banner';

function BannerServices() {

  const { t } = useTranslation();

  return (
    <Banner classBanner= "banner_services" typeScroll="white" >
      <div className='col-lg-12'>
          <h1 className={`mb-5 f--size-60 f_600 w_color line-break`}>
            <Trans i18nKey="servicePage.title_banner_Services">
              <span className='m_color'>Tailored</span>Technological Solutions
            </Trans>
          </h1>
      </div>
      <div className='col-lg-12'>
          <p className={`fadeInUp mb-5 w_color f--size-20 f_400`} data--delay="0.5s">
              {t('servicePage.descri_banner_Services')}
          </p>
      </div>            
    </Banner>
  )
}

export default BannerServices;