import React from 'react';
import Banner from './Banner';
import { Trans, useTranslation } from 'react-i18next';
import './bannerLanding.css';

function BannerLanding() {

    const { t } = useTranslation('translation', 
    { keyPrefix: 'staff' });

  return (
    <Banner classBanner="text-center banner-staff-augmentation" >
        <div className="col-lg-7 col-md-7 col-sm-12">
            <h2 className=" line-break l_height09 w_color f_600 z-index99 order-first text-left">
                <Trans i18nKey="staff.banner_staffAug.title">
                    Descubre como hacer <span>realidad</span> tus ideas de <span>transformación</span> <span >tecnológica</span> con talento especializado en TI
                </Trans>
            </h2>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-7  ">
            <div className='colum-banner'>
                <h1 className='w_color f_p f_500 f--size-25 py-2'>{t('banner_staffAug.text_img')}</h1>
            </div>
        </div>
        <div className="col-lg-7 col-md-12 col-sm-12 order-last">
            <h3 className={`f--size-30 l_height_p w_color mb-5 pr_0 text-left`} data--delay="0.5s">
                {t("banner_staffAug.sub_title")} 
            </h3>
        </div>
    </Banner>
  )
}

export default BannerLanding;