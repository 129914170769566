import React, {  useState } from "react";
import { useTranslation } from 'react-i18next';
import ParallaxTitle from '../../Titles/ParallaxTitle';
import { EffectFade, Autoplay, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import './dreamcoders.css'

function ItemFormat({ verb, name, position}) {

  return (
    <div className="col-lg-12 col-12 p-sm-0">
            <div className="">
              <h3 className="verb_team f_p f--size-60 f_800">{verb}</h3>  
            </div>
            <div className="text-left">
                <h4 className="mb-1 f--size-25 w_color"><stong>{name}</stong> - {position}</h4>
            </div>
    </div>
  );
}

export default function SliderDreamcoders() {

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const {t} = useTranslation();
    const oursdreamcoders = t('careers.ours_dreamcoders', { returnObjects: true })

  return (
    <section className="sec_pad our-dreamcoders dreams bg_dark">
      <div className="container">
        <div className="row align-items-center">
          <main className="col-lg-6">
            <ParallaxTitle 
                Title={t('careers.ours_dreamcoders_title')}
                classDiv="text-left pb-3"
                h2Class="text-left m_color f--size-30 f_600 mb-4" />
          <Swiper
              modules={[Thumbs, Autoplay, EffectFade]}
              thumbs={{ swiper: thumbsSwiper }}
              effect={'fade'}
              autoplay={{ delay: 600000 }}
              className=""
            >

              {
                oursdreamcoders.map((item, index) => (
                  <SwiperSlide key={index}>
                    <ItemFormat
                      verb={item.verb}
                      name={item.name}
                      position={item.position}
                    />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </main>
          <div className="col-lg-6 bg-circles">
            <Swiper
              modules={[Thumbs, EffectFade]}
              watchSlidesProgress
              onSwiper={setThumbsSwiper}
              effect={'fade'}
              className="dreamsImg"
            >
              <SwiperSlide>
                <img src={require("img/dreamcoders/DreamCoders-Andres.png")} alt="Dreamcoders Andres" width="auto" height="100%" loading="lazy"/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("img/dreamcoders/DreamCoders-Lina.png")} alt="Dreamcoders Lina" width="auto" height="100%" loading="lazy"/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("img/dreamcoders/DreamCoders-Anderson.png")} alt="Dreamcoders Anderson" width="auto" height="100%" loading="lazy"/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={require("img/dreamcoders/DreamCoders-Lorena.png")} alt="Dreamcoders Lorena" width="auto" height="100%" loading="lazy"/>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}
