import React from 'react';
import Banner from '../../Banner/Banner';


function BannerProcess({ title, description, img_process}) {

    

  return (
    <Banner classBanner= "-bg-section banner--services" typeScroll="dark" >
        <div className="col-lg-7 seo--banner-content text-left">
            <h1 className="my-5 f--size-40 t_color f_600">
                {title}
            </h1>

            <p className="mb-5 p_color f--size-20 pr_0">
                {description}
            </p>
        </div>
        <div className="col-lg-5" >
            <figure className="banner--img" >
                <img src={require (`img/process/${img_process}` )} width="400" alt="process" loading="lazy" />               
            </figure>
        </div>          
  </Banner>
  )
}

export default BannerProcess;