import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from 'assets/Constants';
import './formLand.css'
import swal from 'sweetalert';

function FormLanding () {

    const { t } = useTranslation('translation', 
    { keyPrefix: 'staff' });
    const form = useRef();

        // Validar ReCAPTCHA

        const [recaptchaValid, setRecaptchaValid] = useState(false);
        const RecaptchaChange = (value) => {
    
            setRecaptchaValid(!!value);
            console.log("Captcha value:", value);
        }
       
        const sendEmail = (event) => {

            event.preventDefault();
    
        if(recaptchaValid) {
            emailjs.sendForm('service_gmail', 'landing_form', event.target, 'user_DK3x36R2nsn4W5NwGBIou')
            .then((result) => {
                swal({
                    title: t('landing_form.form_text_modal'),
                    icon: "success",
                });
                console.log(result.text + " ENVIADO");
            }, (error) => {
                console.log(error.text + " ERROR");
            });

            setTimeout(function() {
                event.target.reset(); // Resetea el formulario después de 3 segundos (3000 milisegundos)
            }, 2000);

        } else {
            console.log(t('landing_form.form_text_error'));
        }
  
    };


        return(
            <section className={` dark sec_pad contact `}>
            <div className='container'>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-sm-12 col-md-10"> 
                            <label><h2 class="f_p w_color f--size-30 f_600 mb-4 l_height_p mb_40">{t('landing_form.title')}</h2></label>
                            <form ref={form} onSubmit={sendEmail} className="contact_form_box needs-validation was-validated" method="post" id="contactForm">
                                <div className="row text-left">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input className="form-control m_color" color="white" type="text" id="name" name="name" placeholder={t("landing_form.name")} required/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input className="form-control input" type="tel" name="phone" id="phone" pattern="[0-9]*" inputMode="numeric" placeholder={t("landing_form.phone")} required />                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <input className="form-control" type="email" name="email" id="inputEmail4" placeholder={t("landing_form.email")} required/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <input className="form-control" type="text" id="company" name="company" placeholder={t("landing_form.company")} required/>
                                        </div>
                                    </div>                                    
                                    <div className="col-lg-12">
                                        <div className="form-group ">
                                            <textarea className="form-control"  name="message" id="message" cols="30" rows="10" placeholder={t("landing_form.message")} required></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="text-left mb-3">
                                            <label className="w_color form-check-label" for="gridCheck">{t("landing_form.privacyPolicy")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <ReCAPTCHA
                                        sitekey={`${RECAPTCHA_SITEKEY}`}
                                        size="invisible"
                                        onChange={RecaptchaChange} />                                 
                                </div>    
                                <button type="submit" className="mb-1 btn_six f_p f_600 btn-land" >{t("landing_form.btn")}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    
    export default FormLanding;