import React from 'react'
import { useTranslation } from 'react-i18next';
import './roleProfile.css'

export default function RoleProfile({rol, color}) {

    const { t } = useTranslation();

  return (
    <section className='profile--role sec_pad d-flex justify-content-center'>
        <div className='container '>
            <h2 className={` rol-title text-center f--size-50 t_color f_600`}>{t('profile_job.profile_rol')}</h2>
            <div className={`px-4 py_40 `}>
                <p className='pt-3 text-center line-break p_color '>{rol}</p>
            </div>
        </div>
    </section>
  )
}
