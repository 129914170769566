import React from 'react';
import { useParams, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ID_GOOGLE_ANALYTICS } from '../assets/Constants';
import ReactGA from 'react-ga';
import MetaDecorator from '../components/MetaDatos/MetaDecorator';
import BannerProfile from "../components/Profile/BannerProfile/BannerProfile";
import ExpertProfile from '../components/Profile/ExpertProfile/ExpertProfile';
import RoleProfile from '../components/Profile/RoleProfile/RoleProfile';
import SliderProfile from '../components/Profile/SliderProfile/SliderProfile';
import BannerFormProfile from '../components/Profile/BannerFormProfile/BannerFormProfile';



function Profiles( { isActive } ) {

    let { urlId } = useParams();
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    
    let listJobs;
      (lang === 'es')
      ? listJobs = require('../assets/jobs/esJobs.json')
      : listJobs = require('../assets/jobs/enJobs.json')

      const jobsColors = ['color1', 'color2', 'color3', 'color4'];

      listJobs.forEach((listJobs, index) => {
          listJobs.color = jobsColors[index % jobsColors.length];
      });
    
    let getProfile;
    if (isActive) {
      getProfile = listJobs.find(job => job.url === urlId);
    }
      
    if (!getProfile || !isActive){
      return <Redirect to="/careers" />;
    }

    // Configuración de Google Analytics
    ReactGA.initialize ({ID_GOOGLE_ANALYTICS}); 
    ReactGA.pageview (`/careers/${urlId}`);

    const { name , description, color, experts, rol, modality } = getProfile;


  return (
    <>        
        <MetaDecorator 
          title={name + ' | Work with us: Join the future'}
          description={description}
          type='Website'
          url={'careers/' + urlId} />
        <BannerProfile name={name} description={description} color={color} />      
        <ExpertProfile 
          expertProfile={experts}
          modality={modality}
          name={name}
          color={color} />
        <RoleProfile rol={rol} color={color} />
        <SliderProfile />    
        <BannerFormProfile 
          title={t('profile_job.profile_form_title')}
          color={color} />

    </>
  )
}

export default Profiles;