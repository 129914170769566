import React from 'react';
import './banner-blog.css';
import { useTranslation } from 'react-i18next';


function BannerBlog () {

  const { t } = useTranslation();
 
  return (
    <>
    <section className={`d-flex align-items-center banner--blog`} id="blog">
        <div className="container">  
        <div className='elements-banner-blog'>
          <img className="figure1 position-absolute " src={require("img/blog/figura_banner_blog_1.svg").default} title="Figura Banner Blog" loading='lazy' alt="Banner Blog" />
          <img className="figure2 position-absolute " src={require("img/blog/figura_banner_blog_2.svg").default} title="Figura Banner Blog" loading='lazy' alt="Banner Blog" />
          <img className="figure3 position-absolute " src={require("img/blog/figura_banner_blog_3.svg").default} title="Figura Banner Blog" loading='lazy' alt="Banner Blog" height={200}/>
          <img className="figure4 position-absolute " src={require("img/blog/figura_banner_blog_4.svg").default} title="Figura Banner Blog" loading='lazy' alt="Banner Blog" />
          <img className="figure5 position-absolute " src={require("img/blog/figura_banner_blog_5.svg").default} title="Figura Banner Blog" loading='lazy' alt="Banner Blog" />
          <img className="figure6 position-absolute " src={require("img/blog/figura_banner_blog_5.svg").default} title="Figura Banner Blog" loading='lazy' alt="Banner Blog" />

        </div>
                     
          <div className="row ">
              <div className="col-md-12 col-lg-12 col-sm-12 text-center seo--banner-content">
                  <h1 className="f--size-90 w_color f_700">DreamBlog</h1>        
              </div>
          </div>
        </div>
    </section>
    <section className='post-list -bg-section pt-5 pb-4'>        
      <div className="container">
        <div>
          <h2 className='t_color f--size-40 f_600'>{t('blog.subtitle_blog')}</h2>
          <p className='p_color f--size-20 f_500'>{t('blog.description_blog')}</p>
        </div>
      </div>
    </section>  
    </>
  )
}

export default BannerBlog ;