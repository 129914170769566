import React, {Component} from 'react';
import FormCareers from '../../Forms/FormCareers/FormCareers'
import './bannerFormProfile.css'

class BannerFormCareers extends Component{
    render(){


            let {title, color} = this.props;

        return(
            <section className={`profile--form ${"bg_" + color} sec_pad`}>
            <div className='container'>
              <div className='row justify-content-center align-items-center'>
                <div className='col-lg-5'>
                  <h2 className=" w_color pb-2 l_height50 f_600  f--size-70">{title}</h2>
                </div>
                <div className='col-lg-7'>
                  <FormCareers
                    btn_color= {color}/>
                </div>
              </div>
            </div>
          </section>
  
        )
    }    
}


export default BannerFormCareers;
