import React from "react";
import '../components/Careers/careers.css';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Benefits from "./Benefits";
import VideoCareers from '../components/Careers/Video/VideoCareers';
import MetaDecorator from '../components/MetaDatos/MetaDecorator';
import ReactGA from 'react-ga';
import { ID_GOOGLE_ANALYTICS } from '../assets/Constants';
import BannerCareers from "../components/Careers/BannerCareers/BannerCareers";
import TabCareers from "../components/Careers/TabCareers/TabCareers";
import Profiles from "./Profiles";
import Dreamcoders from "components/Careers/OursDreamcoders/Dreamcoders";

 

    // Configuración de Google Analytics
    ReactGA.initialize ({ID_GOOGLE_ANALYTICS}); 
    ReactGA.pageview ("/careers");



function Careers() {


    let { path, url } = useRouteMatch();
    const { t } = useTranslation();

    let statusProfile = false;

  
    return (
          <Switch>
            <Route exact path={path}>
                <MetaDecorator 
                  title={t("meta_datos.meta_title_Jobs")}
                  description={t("meta_datos.meta_description_Jobs")}
                  url="careers" />
                <VideoCareers />
                <BannerCareers />
                {statusProfile && <TabCareers pathUrl={url}/>}
                <Dreamcoders />
                <Benefits />
            </Route>
            <Route path={`${path}/:urlId`}>
              <Profiles isActive={statusProfile} />
            </Route>
          </Switch>
    );
  }
  


export default Careers;