import React, { useState, useEffect } from 'react';
import './searchList.css'
import enPost from 'assets/posts/postsEn.json';
import esPost from 'assets/posts/postsEs.json';
import { useTranslation } from 'react-i18next';
import PostItem from '../PostList/PostItem';

function SearchList({pathURL}) {
  const { t, i18n } = useTranslation();
  const [searchText, setSearchText] = useState(''); // almacena el texto que el usuario ingresa en el campo
  const [availablePosts, setPosts] = useState([]); // almacena todos los posts disponibles en la aplicación
  const [currentPosts, setCurrentPosts] = useState([]); // almacena los posts que actualmente se mostrarán en función del texto de búsqueda y del idioma

  useEffect(() => {
    const lng = i18n.language;
    const postsData = lng === 'en' ? enPost : esPost;
    setPosts(postsData);
    setCurrentPosts(postsData);
  }, [i18n.language]);

  useEffect(() => {
    const filteredPosts = availablePosts.filter((post) =>
      post.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setCurrentPosts(filteredPosts);
  }, [searchText, availablePosts]);

  return (
    <>
      <div className='searchField'>
        <svg class="icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
        <input
          type="text"
          placeholder={t('blog.searchField')}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className='row'>

        {
          currentPosts.map((post, index) => (
            <PostItem
              key={index}
              
              url={post.url}
              title={post.title}
              date={post.date}
              imglink={post.imglink}
              read={post.read}
              content={post.content}
              category={post.category} />

          ))
        }
      </div>
    </>
  );
};

export default SearchList;
