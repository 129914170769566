import React from 'react';
import './bannerProfile.css'

export default function BannerProfile({ color, name, description }) {
  return (
    <section className={`profile--banner ${"bg_" + color}  sec_pad `}>
        <div className='container position-relative'>
            <h1 className="pt-4 w_color text-center pb-2">
            <strong>DreamCoder:<br></br></strong> 
                {name}
            </h1>
            <p className='text-center w_color px-4 pb-3'>
                {description}
            </p>
        </div>
        
    </section>  
  )
}
