import React from 'react';
import { useTranslation } from 'react-i18next';
import './expertProfile.css';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';

export default function ExpertProfile({expertProfile, modality, color, name}) {

    const { t } = useTranslation();
    const classIcon = modality === 'Remote' ? "ti-signal" : "ti-user";
    let experts = expertProfile;

  return (
    <section className='profile--expert -bg-section pb-3'>
        <div className='container'>
        <Breadcrumb 
          sectionClass='-bg-section py-3'
          url='/careers'
          inicio={t('menu.careers')}
          namePage={name} />
        <div className='row'>
          <div className='col-lg-5 d-flex align-items-center'>
            <iframe className='w-100' 
                    frameBorder="0" 
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  
                    title="Sueña para transformar el futuro." 
                    width="640" 
                    height="360" 
                    src={t('careers.url_video')} >
            </iframe>  
          </div>
          <div className='col-lg-7'>
            <h3 className="f_600 t_color">{modality}
            <i className={`pl-2 f--size-20 f_600 ${classIcon}`}></i></h3>
            <p className='p_color l_height_p f--size-18'>{t('profile_job.profile_subtitle')}</p>
            <h2 className='p_color f_600 f--size-20'>{t('profile_job.profile_how_you_can')}</h2>  
            <ul className='p_color list-expert'>
                <li className='f_p f--size-18'>{t('profile_job.profile_expert_living')}</li>
                <li className='f_p f--size-18'>{t('profile_job.profile_expert_imagining')}</li>
                <li className='f_p f--size-18'>{t('profile_job.profile_expert_assuming')}</li>
                <li className='f_p f--size-18'>{t('profile_job.profile_expert_focusing')}</li>
                {
                  experts.map((item, i) =>{
                    return (
                      <li key={i} className='f_p f--size-18'>{item}</li>
                    )
                  })
                }                      
            </ul>

          </div>
        </div>
        </div>
    </section>
  )
}
