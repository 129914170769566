import React  from 'react';
import ParallaxTitle from '../../Titles/ParallaxTitle';
import "./testimonial.css"
import { EffectCreative, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from 'react-i18next';
import { IoMdQuote } from "react-icons/io";
import { BottonsNavSwiper } from 'components/Bottons/Bottons';




function Testimonial (){

    const { t } = useTranslation('translation', { keyPrefix: 'homePage.testimonios' });
    const testimonial = t('testimoniosItems', { returnObjects: true })
    
    return(
            <section className="testimonial overflow-hidden -bg-section sec_pad " id="testimonial">
                <div className="container ">
                    <div className='row justify-content-center'>
                        <div className='col-md-12 col-lg-12 col-sm-12 d-flex align-items-center'>
                            <ParallaxTitle  
                                Title={t('testim_title')}
                                Paragraph={t('testim_subtitle')}
                                Pclass="p_color"
                                classDiv="pb-4 px-3 text-center container" 
                                h2Class="t_color f--size-40 f_600" />
                        </div>
                        <div className='col-sm-12 col-xl-9'>
                            <Swiper

                                grabCursor={true}
                                effect={"creative"}
                                spaceBetween={10}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',   
                            
                                  }}
                                creativeEffect={{
                                    prev: {
                                        translate: ["-125%", 0, -100],
                                    },
                                    next: {
                                        translate: ["125%", 0, -100],
                                    }
                                    }}                             
                                modules={[ EffectCreative, Navigation]}
                                className="swiper-testimonios overflow-inherit py-4 "
                            >
                                {
                                    testimonial.map((item, i) => {
                                        return (
                                            <SwiperSlide className='testimonial--box ' key={i}>                          
                                                <div className="testimonial--item ">
                                                    <div className="content">
                                                        <div className='d-flex justify-content-end f--size-30'><IoMdQuote className='testimonial-icon'/></div>
                                                        <strong><h5 className='f_700 f--size-25'>{item.name}</h5></strong>
                                                        <p className='f_600 f--size-15'>{item.position}</p>
                                                        <p className='f_500 b_color f--size-18'>{item.description}</p>
                                                    </div>                                                   
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                                {/* <div>
                                    <div className="btn-swiper swiper-button-next">
                                        <IoIosArrowDropright size={35}/>
                                    </div>
                                    <div className="btn-swiper swiper-button-prev">
                                        <IoIosArrowDropleft size={35}/>
                                    </div>
                                </div> */}
                                <BottonsNavSwiper />
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        )
   }

export default Testimonial;

